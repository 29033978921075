import CallToActionDataTransformerBlock from '@activebrands/core-web/libs/storyblok/content/CmsDataTransformerBlock/CallToActionDataTransformerBlock';
import { styled } from '@activebrands/core-web/libs/styletron';

const HeroWrapper = styled('section', ({ $isGrid }) => ({
    width: '100%',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
}));

const ContentWrapper = styled('section', ({ $isGrid }) => ({
    aspectRatio: 'var(--ratio-module-call-to-action)',
    marginBottom: $isGrid ? 0 : 'var(--margin-bottom-module)',
    width: '100%',
    maxHeight: 'calc(100vh - var(--height-header))',
    height: '100%',
}));

interface CallToActionModuleProps {
    $heroStyling?: any;
    isGrid?: boolean;
    isHero?: boolean;
}

const CallToActionModule = ({
    $heroStyling = { height: '100vh' },
    isGrid = false,
    isHero = false,
    ...rest
}: CallToActionModuleProps) => {
    if (isHero) {
        return (
            <HeroWrapper $style={$heroStyling} $isGrid={isGrid}>
                <CallToActionDataTransformerBlock isHero {...rest} />
            </HeroWrapper>
        );
    }

    return (
        <ContentWrapper $isGrid={isGrid}>
            <CallToActionDataTransformerBlock {...rest} />
        </ContentWrapper>
    );
};

export default CallToActionModule;
